import { h, Fragment, FunctionalComponent } from 'preact';
import { useMemo, useEffect } from 'preact/hooks';
import getOptimizedURL from '../../utils/getOptimizedImage';
import { ITranslationItems } from '../../models/Translation';
import {
  Container,
  Content,
  Footer,
  Description,
  TopBar,
  PositionedCloseIcon,
  Header,
  Logo,
  MainContent,
  Cards,
  DismissButton,
  PromoScreenContent,
  PromoBackdrop,
  SubmitButton,
} from './promoScreenStyled';

import PromoCard from './promoCard';
import { SupportedEvents, SupportedAmplitudeEvents } from '../../enums/event';
import { useEventHandler } from '../../providers/eventHandlerProvider';

const MIN_COLUMN_NUM = 2;

export interface IPromoScreenProps {
  onClose: () => void;
  onRedirect: () => void;
  content: ITranslationItems;
}

const PromoScreen: FunctionalComponent<IPromoScreenProps> = ({ onClose, onRedirect, content }) => {
  const { dispatchEvent } = useEventHandler();

  const promoData = useMemo(() => {
    const { translations } = content || {};

    return [
      { id: 1, title: translations?.imageOneTitle, imageUrl: translations?.imageOneUrl },
      { id: 2, title: translations?.imageTwoTitle, imageUrl: translations?.imageTwoUrl },
      { id: 3, title: translations?.imageThreeTitle, imageUrl: translations?.imageThreeUrl },
      { id: 4, title: translations?.imageFourTitle, imageUrl: translations?.imageFourUrl },
      { id: 5, title: translations?.imageFiveTitle, imageUrl: translations?.imageFiveUrl },
      { id: 6, title: translations?.imageSixTitle, imageUrl: translations?.imageSixUrl },
    ];
  }, [content]);

  const onAcceptPromoHandler = () => {
    dispatchEvent(SupportedEvents.onAnalyticsEvent, SupportedAmplitudeEvents.select5erCTA);
    onRedirect();
  };

  useEffect(() => {
    dispatchEvent(SupportedEvents.onAnalyticsEvent, SupportedAmplitudeEvents.view5erPromo);
  }, []);

  return (
    <Fragment>
      <PromoBackdrop isFullCovered isVisible />
      <PromoScreenContent>
        <Container>
          <TopBar>
            <Logo
              src={getOptimizedURL({
                imageUrl: content.translations.logoUrl,
                width: '150',
              })}
            />
            <PositionedCloseIcon onClick={onClose} />
          </TopBar>
          <Content>
            <Header>{content.translations.title}</Header>
            <Description>{content.translations.subtitle}</Description>

            <MainContent>
              <Cards columns={promoData?.length / MIN_COLUMN_NUM || MIN_COLUMN_NUM}>
                {promoData?.map((promo) => (
                  <PromoCard key={promo.id} {...promo} />
                ))}
              </Cards>
            </MainContent>
          </Content>
          <Footer>
            <SubmitButton small lowercase onClick={onAcceptPromoHandler}>
              {content.translations.primaryActionText}
            </SubmitButton>
            <DismissButton small secondary lowercase onClick={onClose}>
              {content.translations.secondaryActionText}
            </DismissButton>
          </Footer>
        </Container>
      </PromoScreenContent>
    </Fragment>
  );
};

export default PromoScreen;
