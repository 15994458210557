{
  "ACCESS_POLICIES": {
    "APES_API": "https://api-charlie.qa.cd.beachbodyondemand.com/user-apes/apes",
    "APES_API_KEY": "dxJ6LznXQca2RZpkIP1H178ZXmFkbEfqTfZMXA6b"
  },
  "ALGOLIA": {
    "APP_ID": "UQQ4ZDVOKP",
    "API_KEY": "b3865c241cb857ef2a73f2869c9b039d"
  },
  "ALL_ACCESS": "https://fs6.formsite.com/beachbody/form161/index.html",
  "APPSYNC": {
    "API_URL": "https://lfa3juap25errdh3e6drxzxaqi.appsync-api.us-west-2.amazonaws.com/graphql",
    "API_KEY": "da2-4wdbr3djarhureydymakdvds6m",
    "CACHE_EXPIRE_MINUTES": 10,
    "IS_PREVIEW": false
  },
  "APP_DOMAIN_LIST": {
    "BOD": "https://www.beachbodyondemand.com",
    "TBB": "https://www-tbbqa5.beachbody.com",
    "BLOG": "https://www-qa6.beachbodyondemand.com/blog",
    "COO": "https://cms.qa5.coo.tbbtest.com",
    "SHAC": "https://www-shareacartqa5.beachbody.com",
    "BB": "https://qa4.harmony.beachbody.com"
  },
  "BOD_CHECKOUT_URL": "/shop/{{countryCode}}/d/bodcheckout",
  "BOD_GROUP_URL": "/groups/{id}/portal",
  "BODI": {
    "ENTITLEMENT": "BODINT"
  },
  "BODI_VIDEOS_DEEP_LINKING_ENABLED_FLAG": false,
  "CART_API": {
    "URL": "https://www-tbbdev5.beachbody.com/rest/model/atg/commerce/ShoppingCartActor/bodsummary"
  },
  "COCOA_API": {
    "URL": "https://cocoa-echo.qa.cd.beachbodyondemand.com/v1.0",
    "KEY": "RVDpxtITDvaljcNbsv01e16X6Liv2W3T9KSkwjcf"
  },
  "CONTENFUL_DOMAINS": {
    "BeachbodyOnDemand.com": "BOD",
    "TeamBeachbody.com": "TBB",
    "BODBlog": "BLOG",
    "CoachOffice": "COO",
    "ShareACart": "SHAC"
  },
  "DOMAIN_WHITELIST": [".beachbody.com", ".beachbodyondemand.com", "coo.tbbtest.com"],
  "ENTITLEMENTS_API": {
    "URL": "https://ebs.qa.gateway.beachbody.com/userEntitlements",
    "API_KEY": "xn2EkGJmrf6PlyWRUPJf375Meq5AXEIoacRVHsxk"
  },
  "GROUPS_API": {
    "URL": "https://api.qa.cd.beachbodyondemand.com/groups-api/v2/profile/groups",
    "API_KEY": "RVDpxtITDvaljcNbsv01e16X6Liv2W3T9KSkwjcf"
  },
  "IFRAME_URL": "https://local.unification-iframe.beachbody.com:8082/",
  "PROFILE": {
    "URL": "https://api.qa.cd.beachbodyondemand.com",
    "API_KEY": "RVDpxtITDvaljcNbsv01e16X6Liv2W3T9KSkwjcf"
  },
  "GEOLOCATION": {
    "URL": "https://www.beachbodyondemand.com/api/geo"
  },
  "VIDEO_PLAYER": {
    "API": "https://api.dev.cd.beachbodyondemand.com/video-api/v2",
    "API_KEY": "HTsMuP9w9m2raItYDT4cdk6oSkTX7wX5bVDdrm08",
    "CHROMECAST_APP_ID": "AD56322A",
    "CONVIVA_CUSTOMER_KEY": "9e58b48400889d4326b7af3e24f24343053cbacc",
    "LEGAL_API": "https://devices-static-qa6.beachbodyondemand.com/v2/legal",
    "RATINGS_API": "https://api.dev.cd.beachbodyondemand.com/ratings-api/ratings",
    "RATINGS_API_KEY": "HTsMuP9w9m2raItYDT4cdk6oSkTX7wX5bVDdrm08",
    "RETRY_ATTEMPTS": 3,
    "VHS_API": "https://vhs-qa.api.beachbodyondemand.com/v2",
    "VHS_API_KEY": "UAlUwaM6L45Q6i4LdDEYj9TskavxS1uTaR0YTkMS",
    "USE_COCOA_API": false
  },
  "VIDEO_PLAYER_WAIVER": {
    "URL": "https://api.qa.cd.beachbodyondemand.com/waivers-api/waivers",
    "API_KEY": "RVDpxtITDvaljcNbsv01e16X6Liv2W3T9KSkwjcf"
  },
  "TBB_MY_ACCOUNT_PATH": "/shop/{countryCode}/account/settings",
  "TBB_SHOP_LIST": {
    "en_ca": "7000002",
    "fr_ca": "7000002",
    "fr_fr": "TBB-FR",
    "en_gb": "TBB-GB",
    "en_us": "1400002",
    "es_us": "1400002"
  },
  "ELEMENT_ID_MAP": {
    "BOD": {
      "topNav": "unification-topNav",
      "footer": "unification-footer"
    },
    "TBB": {
      "topNav": "globalNav",
      "footer": "globalFooter"
    },
    "SHAC": {
      "topNav": "globalNav",
      "footer": "globalFooter"
    },
    "COO": {
      "topNav": "global-topNav",
      "footer": "global-footer"
    },
    "CO": {
      "topNav": "global-topNav",
      "footer": "global-footer"
    }
  },
  "BODI_PROMO_MODAL_ENABLED_FLAG": false,
  "IS_TEALIUM_ENABLED_FLAG": true,
  "IS_TEST_IDS_ENABLED_FLAG": false,
  "PERSISTENT_URL_PARAMS": [
    "tracking",
    "source",
    "icid",
    "trainername",
    "programid",
    "programname",
    "referralprogramid",
    "referringrepid",
    "locale"
  ],
  "HIDDEN_SHOPPING_CATEGORIES": [],
  "BODI_MULTIPLE_TRAINER_TBB_PARAMS": {
    "Autumn & Shaun T": "AutumnCalabrese-ShaunT",
    "Idalis & Megan": "IdalisVelasquez-MeganDavies",
    "Amoila & Joel": "AmoilaCesar-JoelFreeman",
    "Jericho & Elise": "JerichoMcMatthews-EliseJoan",
    "Amoila & Lacee": "AmoilaCesar-LaceeGreen"
  },
  "DIRECT_RESPONSE_MARKETING_REGIONS": ["AU"],
  "SEARCH_BAR_DEBOUNCE_INTERVAL_MS": 400,
  "VERSION_NAME_URL": "https://unification.prod.cd.beachbodyondemand.com/latest/version.txt"
}
